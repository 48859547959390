export const comments = {
  ja: {
    score: {
      up: `生活習慣スコアは12月より%d点上がりました。日々の心がけの成果が目に見える形で表れていますね。<br>
        いよいよ<b>Walkチーム対抗戦3rd</b>と<b>ウェル知2021復習ウィーク</b>が開催されます！運動不足になりがちな寒い季節は、イベントを活用して楽しみながら強いカラダをつくりましょう。2月8日からWalk個人チャレンジ3rdのエントリーも始まります！`,
      down: `生活習慣スコアは12月より%d点下がりました。分類別評価を見ながら、振り返ってみましょう。<br>
        いよいよ<b>Walkチーム対抗戦3rd</b>と<b>ウェル知2021復習ウィーク</b>が開催されます！運動不足になりがちな寒い季節は、イベントを活用して楽しみながら強いカラダをつくりましょう。2月8日からWalk個人チャレンジ3rdのエントリーも始まります！`,
      equal: `生活習慣スコアは12月と同じ点数です。分類別評価を見ながら、振り返ってみましょう。<br>
        いよいよ<b>Walkチーム対抗戦3rd</b>と<b>ウェル知2021復習ウィーク</b>が開催されます！運動不足になりがちな寒い季節は、イベントを活用して楽しみながら強いカラダをつくりましょう。2月8日からWalk個人チャレンジ3rdのエントリーも始まります！`,
    },
    steps: {
      up: `歩数スコアは12月より%d点上がりました。<br>
      「<b><a href="/library/7a4425e6-a297-11eb-8810-06d158a16ffc">&wellウォーク必勝！たくさん歩いても辛くない！カラダを傷めない歩き方</a></b>」のWeeklyプログラムを見て、健康的に歩きましょう。`,
      down: `歩数スコアは12月より%d点下がりました。<br>
      「<b><a href="/library/7a4425e6-a297-11eb-8810-06d158a16ffc">&wellウォーク必勝！たくさん歩いても辛くない！カラダを傷めない歩き方</a></b>」のWeeklyプログラムを見て、健康的に歩きましょう。`,
      equal: `歩数スコアは12月と同じです。<br>
      「<b><a href="/library/7a4425e6-a297-11eb-8810-06d158a16ffc">&wellウォーク必勝！たくさん歩いても辛くない！カラダを傷めない歩き方</a></b>」のWeeklyプログラムを見て、健康的に歩きましょう。`,
    },
    exercise: {
      up: `運動のスコアは12月より%d点上がりました。<br>
      2月1日から<b>毎週火曜日</b>に、「身体を調える！姿勢改善ピラティス」のWeeklyプログラムを配信！初心者にもオススメです。`,
      down: `運動のスコアは12月より%d点下がりました。<br>
      2月1日から<b>毎週火曜日</b>に、「身体を調える！姿勢改善ピラティス」のWeeklyプログラムを配信！初心者にもオススメです。`,
      equal: `運動のスコアは12月と同じ点数です。<br>
      2月1日から<b>毎週火曜日</b>に、「身体を調える！姿勢改善ピラティス」のWeeklyプログラムを配信！初心者にもオススメです。`,
    },
    meal: {
      up: `食事のスコアは12月より%d点上がりました。<br>
      2月はバレンタインデーで、美味しそうなチョコレートを目にする機会が増える時期ですね。間食は1日200kcalを目安に！`,
      down: `食事のスコアは12月より%d点下がりました。<br>
      2月はバレンタインデーで、美味しそうなチョコレートを目にする機会が増える時期ですね。間食は1日200kcalを目安に！`,
      equal: `食事のスコアは12月と同じ点数です。<br>
      2月はバレンタインデーで、美味しそうなチョコレートを目にする機会が増える時期ですね。間食は1日200kcalを目安に！`,
    },
    drinking: {
      up: `飲酒のスコアは12月より%d点上がりました。<br>
      寒い時期は、ヒートショックを防ぐため飲酒直後や飲み過ぎた日の入浴は避けましょう。`,
      down: `飲酒のスコアは12月より%d点下がりました。<br>
      寒い時期は、ヒートショックを防ぐため飲酒直後や飲み過ぎた日の入浴は避けましょう。`,
      equal: `飲酒のスコアは12月と同じ点数です。<br>
      寒い時期は、ヒートショックを防ぐため飲酒直後や飲み過ぎた日の入浴は避けましょう。`,
    },
    sleep: {
      up: `睡眠のスコアは12月より%d点上がりました。<br>
      覚醒作用のあるカフェインはチョコレートにも含まれています。寝る直前は控えるようにしましょう。`,
      down: `睡眠のスコアは12月より%d点下がりました。<br>
      覚醒作用のあるカフェインはチョコレートにも含まれています。寝る直前は控えるようにしましょう。`,
      equal: `睡眠のスコアは12月と同じ点数です。<br>
      覚醒作用のあるカフェインはチョコレートにも含まれています。寝る直前は控えるようにしましょう。`,
    },
    stress: {
      up: `実は、笑わない人はよく笑う人と比べて死亡率が約2倍です。詳細は、「心と体の免疫力を高める笑い健康法」のWeeklyプログラムをご覧ください。<b><a href="/library/tag/9a8787dc-5e2f-11ec-9ce8-0af363eb5062">＃リフレッシュ</a>で検索！</b>`,
      down: `実は、笑わない人はよく笑う人と比べて死亡率が約2倍です。詳細は、「心と体の免疫力を高める笑い健康法」のWeeklyプログラムをご覧ください。<b><a href="/library/tag/9a8787dc-5e2f-11ec-9ce8-0af363eb5062">＃リフレッシュ</a>で検索！</b>`,
      equal: `実は、笑わない人はよく笑う人と比べて死亡率が約2倍です。詳細は、「心と体の免疫力を高める笑い健康法」のWeeklyプログラムをご覧ください。<b><a href="/library/tag/9a8787dc-5e2f-11ec-9ce8-0af363eb5062">＃リフレッシュ</a>で検索！</b>`,
    },
  },
  en: {
    score: {
      up: `The lifestyle score has increased by %d points since December. We can see the results of our daily efforts in a tangible way.
      Now it's time for the <b>3rd Walk Team Competition and Well-zhi 2021 Review Week</b>! You can also start entering the 3rd Walk Individual Challenge on February 8th!`,
      down: `The lifestyle score has dropped by %d points since December. We can see the results of our daily efforts in a tangible way.
      Now it's time for the <b>3rd Walk Team Competition and Well-zhi 2021 Review Week</b>! You can also start entering the 3rd Walk Individual Challenge on February 8th!`,
      equal: `The lifestyle score is the same as in December. We can see the results of our daily efforts in a tangible way.
      Now it's time for the <b>3rd Walk Team Competition and Well-zhi 2021 Review Week</b>! You can also start entering the 3rd Walk Individual Challenge on February 8th!`,
    },
    steps: {
      up: `Your score has increased by %d points since December.<br>
      Take a look at the Weekly program "<b><a href="/library/7a4425e6-a297-11eb-8810-06d158a16ffc">&well walk a must! It's not hard to walk a lot! How to walk without damaging your body!</a></b>" and walk in a healthy way.`,
      down: `Your score has dropped by %d points since December.<br>
      Take a look at the Weekly program "<b><a href="/library/7a4425e6-a297-11eb-8810-06d158a16ffc">&well walk a must! It's not hard to walk a lot! How to walk without damaging your body!</a></b>" and walk in a healthy way.`,
      equal: `Your score is the same as it was in December.<br>
      Take a look at the Weekly program "<b><a href="/library/7a4425e6-a297-11eb-8810-06d158a16ffc">&well walk a must! It's not hard to walk a lot! How to walk without damaging your body!</a></b>" and walk in a healthy way.`,
    },
    exercise: {
      up: `Your score has increased by %d points since December.<br>
      Starting on February 1, every Tuesday, we will be offering a weekly program called "Tone Your Body! Posture Improvement Pilates" Weekly Program! It is recommended for beginners.`,
      down: `Your score has dropped by %d points since December.<br>
      Starting on February 1, every Tuesday, we will be offering a weekly program called "Tone Your Body! Posture Improvement Pilates" Weekly Program! It is recommended for beginners.`,
      equal: `Your score is the same as it was in December.<br>
      Starting on February 1, every Tuesday, we will be offering a weekly program called "Tone Your Body! Posture Improvement Pilates" Weekly Program! It is recommended for beginners.`,
    },
    meal: {
      up: `Your score has increased by %d points since December.<br>
      February is Valentine's Day, a time when we see more and more delicious chocolates. Snacking should be 200 kcal a day!`,
      down: `Your score has dropped by %d points since December.<br>
      February is Valentine's Day, a time when we see more and more delicious chocolates. Snacking should be 200 kcal a day!`,
      equal: `Your score is the same as it was in December.<br>
      February is Valentine's Day, a time when we see more and more delicious chocolates. Snacking should be 200 kcal a day!`,
    },
    drinking: {
      up: `Your score has increased by %d points since December.<br>
      In cold weather, avoid taking a bath immediately after drinking or on days when you have had too much to drink to prevent heat shock.`,
      down: `Your score has dropped by %d points since December.<br>
      In cold weather, avoid taking a bath immediately after drinking or on days when you have had too much to drink to prevent heat shock.`,
      equal: `Your score is the same as it was in December.<br>
      In cold weather, avoid taking a bath immediately after drinking or on days when you have had too much to drink to prevent heat shock.`,
    },
    sleep: {
      up: `Your score has increased by %d points since December.<br>
      Caffeine, a stimulant, is also found in chocolate. Try to avoid it just before going to bed.`,
      down: `Your score has dropped by %d points since December.<br>
      Caffeine, a stimulant, is also found in chocolate. Try to avoid it just before going to bed.`,
      equal: `Your score is the same as it was in December.<br>
      Caffeine, a stimulant, is also found in chocolate. Try to avoid it just before going to bed.`,
    },
    stress: {
      up: `In fact, people who do not laugh have about twice the mortality rate as those who laugh often. For more information, please see the Weekly Program on "Laughter Health for Mind and Body Immunity. <b>Search for <a href="/library/tag/9a8787dc-5e2f-11ec-9ce8-0af363eb5062">#Refresh!</a></b>`,
      down: `In fact, people who do not laugh have about twice the mortality rate as those who laugh often. For more information, please see the Weekly Program on "Laughter Health for Mind and Body Immunity. <b>Search for <a href="/library/tag/9a8787dc-5e2f-11ec-9ce8-0af363eb5062">#Refresh!</a></b>`,
      equal: `In fact, people who do not laugh have about twice the mortality rate as those who laugh often. For more information, please see the Weekly Program on "Laughter Health for Mind and Body Immunity. <b>Search for <a href="/library/tag/9a8787dc-5e2f-11ec-9ce8-0af363eb5062">#Refresh!</a></b>`,
    },
  },
}
